<template>
	<div class="page-show-item change_responsive opacity-page" v-if="loaded">
		<div class="main-content">
			<div class="header-with-tabs row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="tabs">
						<div class="title-tab" :class="{active: activeTab == 'list'}" @click="activeTab = 'list', changeTab(activeTab)">{{ $t('roles.tab_list') }}</div>
						<div class="title-tab" :class="{active: activeTab == 'calendar'}" @click="activeTab = 'calendar', changeTab(activeTab)">{{ $t('roles.tab_calendar') }}</div>
					</div>
					<div class="actions">
						<template v-if="!reorderList">
							<button class="btn-tbf white-blue mr-15" :class="{mobile: $resize && $mq.below(600)}" @click="reorderList = true" v-if="activeTab == 'list'">
								<div class="icon">
									<icon-list />
								</div>
								<span v-if="$resize && $mq.above(600)" class="text">{{ $t('responsibility.reorder_btn') }}</span>
							</button>
							<button class="btn-tbf blue show" :class="{mobile: $resize && $mq.below(600)}" @click="createResponsibility">
								<div class="icon">
									<icon-plus />
								</div>
								<span v-if="$resize && $mq.above(600)" class="text">{{ $t('responsibility.create_title') }}</span>
							</button>
						</template>
						<template v-else>
							<button class="btn-tbf grey mr-15" :class="{mobile: $resize && $mq.below(600)}" @click="cancelOrderList">
								<span class="text">{{ $t('general.cancel') }}</span>
							</button>
							<button class="btn-tbf blue show" :class="{mobile: $resize && $mq.below(600)}" @click="saveNewOrderList">
								<span class="text">{{ $t('general.update') }}</span>
							</button>
						</template>
					</div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="tab-content">
				<tab-list v-if="activeTab == 'list'" :data="{ from: 'role', id: $route.params.id, reorderList: reorderList }" :key="`list${keyComponentsRefresh}`" @populateList="populateList"/>
				<tab-calendar v-if="activeTab == 'calendar'" :data="{ from: 'role', id: $route.params.id }" :key="`calendar${keyComponentsRefresh}`"/>
			</div>
		</div>
		<div class="sidebar-user">
			<div class="space-left-custom"></div>
			<div class="content">
				<div class="actions-header">
					<button @click="$router.push({ name: 'roles-edit', params: {id: role.id} })">
						<div class="text ">{{ $t('general.edit') }}</div>
					</button>

					<button class="delete-btn" @click="showModal('delete', {type: 'role', from: 'show_role', model: role})">
						<div class="text ">{{ $t('general.delete') }}</div>
					</button>
				</div>

				<div class="header-user">
					<div class="data">
						<div class="name">{{ role.name }}</div>
					</div>
				</div>

				<div class="box-label-text">
					<div class="label">{{ $t('general.users') }}</div>
					<div class="text">{{ role.users }}</div>
				</div>

				<div class="box-label-text">
					<div class="label">{{ $t('general.description') }}</div>
					<div class="text">{{ role.description }}</div>
				</div>
			</div>
			<div class="space-right-custom"></div>
		</div>
	</div>	
</template>

<script>
	import IconSearch from '../../Icons/Search'
	import IconArrow from '../../Icons/Arrow'
	import IconTrash from '../../Icons/Trash'
	import IconClose from '../../Icons/Close'
	import IconEdit from '../../Icons/EditDots'
	import IconDate from '../../Icons/Date'
	import IconEditMobile from '../../Icons/Edit'
	import IconPlus from '../../Icons/Plus'
	import IconRole from '../../Icons/Role'
	import IconList from '../../Icons/List'
	import TabList from './ResponsibilitiesList'
	import TabCalendar from './ResponsibilitiesCalendar'

	export default {
		components: {
			IconSearch,
			IconArrow,
			IconTrash,
			IconClose,
			IconEdit,
			IconEditMobile,
			IconDate,
			IconPlus,
			IconRole,
			IconList,
			TabList,
			TabCalendar,
		},
		data() {
			return {
				loaded: false,
				loadedList: false,
				role: {},
				responsibilities: [],
				keyComponentsRefresh: 1,
				activeTab: this.$route.query.tab ? this.$route.query.tab : 'list',
				reorderList: false,
				temporaryList: []
			}
		},
		async mounted(){
			if(!this.$auth.user().rights.admins_instance_crud){
				this.$router.push({name: 'forbbiden'})
			}
			
			await this.getRoleData()

			setTimeout(() => {
				var title = this.role.name;
				this.$root.$emit("navbar_title", title);

				if(this.$route.query.tab) {
					this.activeTab = this.$route.query.tab;
				}
			}, 0)

			this.$root.$on('refreshShowRole', () => { 
				this.getRoleData()
			})
		},
		watch: {

		},
		computed: {

		},
		methods: {
			async getRoleData(){
				await axios.get(`/roles/${this.$route.params.id}/show`)
				.then(({data}) => {
					this.role = data.data
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.keyComponentsRefresh++
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			changeTab(item) {
				if(item == 'calendar') { this.reorderList = false  }
				this.$router.push({name: 'roles-show', query: {tab: item}})
			},
			createResponsibility() {
				var dataModal = { from: 'role_list', crud: 'create', roleId: this.$route.params.id }
				this.$root.$emit('open_modal', 'crud_responsibility', dataModal);
			},
			cancelOrderList(){
				this.reorderList = false;
				this.keyComponentsRefresh += 1
			},
			populateList(value){
				this.temporaryList = value
			},
			saveNewOrderList(){
				let order_no = 0;
				
				if(this.temporaryList.length){
					let arrayToStore = this.temporaryList.map(el => {return {id: el.id, order_no: order_no++ }; })

					axios.post(`/${this.role.id}/role-responsibilities/reorder`, {reorder: arrayToStore})
					.then(() => {
						this.reorderList = false;
					})
				} else {
					this.reorderList = false;
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "../../../styles/pages/user_show.scss";
</style>