<template>
	<div class="data-list list-tab-show-page">
		<div class="row-space-tbf">
			<div class="space-left"></div>
			<div class="content" v-if="loaded">
				<FullCalendar
				ref="fullCalendar"
				class="app-calendar-tbf"
				:options="calendarOptions"
				:key="calendarKey"
				></FullCalendar>
			</div>
			<div class="content" v-else>
				<loader-calendar />
			</div>
			<div class="space-right"></div>
		</div>
	</div>
</template>

<script>
    import FullCalendar from "@fullcalendar/vue";
	import dayGridPlugin from "@fullcalendar/daygrid";
	import timeGridPlugin from "@fullcalendar/timegrid";
	import listPlugin from "@fullcalendar/list";
	import interactionPlugin from "@fullcalendar/interaction";
	import esLocale from '@fullcalendar/core/locales/es';
	import roLocale from '@fullcalendar/core/locales/ro';
	import enLocale from '@fullcalendar/core/locales/en-gb';
	import LoaderCalendar from '../../PagesLoaders/Calendar'

	export default {
		components: {
			FullCalendar,
			LoaderCalendar,
		},
		props: {
			data: Object
		},
		watch: {
			$route (to, from){
				if(this.data.from == 'user') {
					this.getUserResponsibilitiesData(this.startMonth.format('YYYY-MM-DD'), this.endMonth.format('YYYY-MM-DD'), true, true)
				}
			}
		},
		data() {
			return {
				loaded: false,
				calendarOptions: {},
				events: [],
				monthsLoaded: [],
				startMonth: '',
				endMonth: '',
				calendarKey: 1
			}
		},
		mounted() {
			const todayDate = moment().startOf("day");
			const TODAY = todayDate.format("YYYY-MM-DD");

			this.calendarOptions = {
				plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
				headerToolbar: {
					left: "prev,next today",
					center: "title",
					right: "dayGridMonth,timeGridWeek,listWeek",
				},
				initialDate: TODAY,
				initialView: 'timeGridWeek',
				firstDay: 1,
				droppable: false,
				navLinks: true,
				selectable: false,
				selectMirror: true,
				locales: [ esLocale, roLocale, enLocale ],
				locale: 'ro',
				buttonText: {
					month: 'Lunar',
					week: 'Sapt.',
					list: 'Lista'
				},
				views: {
					dayGridMonth: { buttonText: "month" },
					timeGridWeek: { buttonText: "week" },
					listWeek: { buttonText: "list" },
				},
				eventMinHeight: 42,
				slotEventOverlap: false,
				editable: false,
				dayMaxEvents: true, 
				lazyFetching: false,
				eventClick: this.viewEvent,
				customButtons: { 
					prev: { 
						click: () => {this.changeMonthCalendar('prev')}
					},
					next: { 
						click: () => {this.changeMonthCalendar('next')}
					}
				}
			}

			this.$root.$on('updateEventCalendar', (data) => {this.updateEventClass(data)})

			this.startMonth = moment(todayDate).add(-2, "M").startOf("month")
			this.endMonth = moment(todayDate).add(2, 'M').endOf("month")

			this.monthsLoaded.push(this.startMonth.format('MM-YYYY'))
			this.monthsLoaded.push(todayDate.format('MM-YYYY'))
			this.monthsLoaded.push(this.endMonth.format('MM-YYYY'))

			switch (this.data.from){
				case 'user':
					this.getUserResponsibilitiesData(this.startMonth.format('YYYY-MM-DD'), this.endMonth.format('YYYY-MM-DD'))
					break
				case 'role':
					this.getRoleResponsibilitiesData(this.startMonth.format('YYYY-MM-DD'), this.endMonth.format('YYYY-MM-DD'))
					break
			}
		},
		methods: {
			getUserResponsibilitiesData(startMonth, endMonth, refresh = false, filter = false) {
				let paramsQuery = {
					start_interval: startMonth,
					end_interval: endMonth
				}

				if(this.$route.query.roles) {
					paramsQuery.role_ids = this.$route.query.roles.split(',').map(Number)
				}

				
				axios.get(`/${this.data.slug}/user-responsibilities-calendar`, {params: paramsQuery } )
				.then(({data}) => {
					if(refresh) {
						var calendarApi = this.$refs.fullCalendar.getApi()
					}

					if(filter) {
						let eventSource = calendarApi.getEventSourceById(this.calendarKey)
						eventSource.remove()
						calendarApi.refetchEvents()
						calendarApi.addEventSource({
							events: data.data,
							id: this.calendarKey
						})
					} else {
						this.events.push(...data.data)
						let newEvents = [...this.events]

						this.calendarOptions.eventSources = [{
							events: newEvents,
							id: this.calendarKey
						}]
					}

					if(refresh) {
						let newEventSource = calendarApi.getEventSourceById(this.calendarKey)
						newEventSource.refetch()
					}
				})
				.finally(() => {
					if(!refresh) {
						this.loaded = true
					}
				})
			},
			getRoleResponsibilitiesData(startMonth, endMonth, refresh = false, filter = false) {
				axios.get(`/${this.data.id}/role-responsibilities-calendar`, {params: {start_interval: startMonth, end_interval: endMonth} } )
				.then(({data}) => {
					this.events.push(...data.data)
					this.calendarOptions.eventSources = [{
						events: this.events,
						id: 1
					}]
				})
				.finally(() => {
					if(refresh){
						let calendarApi = this.$refs.fullCalendar.getApi()
						let eventSource = calendarApi.getEventSourceById(1)
						eventSource.refetch()
					} else {
						this.loaded = true
					}
				})
			},
			viewEvent(arg){
				this.$root.$emit('open_modal', 'show_responsibility', {
					from: this.data.from,
					userId: this.data.id,
					userSlug: this.data.slug,
					id: arg.event.id,
					date: moment(arg.event.start).format('YYYY-MM-DD')
				});
			},
			changeMonthCalendar(direction){
				let calendarApi = this.$refs.fullCalendar.getApi();
				if(direction == 'prev') { calendarApi.prev() } else { calendarApi.next() }

				let currentDate = moment(calendarApi.view.currentStart)
				let prevDate = currentDate.clone().add(-2, "M").startOf("month")
				let nextDate = currentDate.clone().add(2, 'M').endOf("month")

				if(direction == 'prev') {
					if(!this.monthsLoaded.includes(prevDate.format('MM-YYYY'))){
						this.refreshDataCalendar(prevDate.startOf("month").format('YYYY-MM-DD'), prevDate.endOf("month").format('YYYY-MM-DD'))
					}

					this.monthsLoaded.push(prevDate.format('MM-YYYY'))
				} else {
					if(!this.monthsLoaded.includes(nextDate.format('MM-YYYY'))){
						this.refreshDataCalendar(nextDate.startOf("month").format('YYYY-MM-DD'), nextDate.endOf("month").format('YYYY-MM-DD'))
					}
					
					this.monthsLoaded.push(nextDate.format('MM-YYYY'))
				}
			},
			updateEventClass(data) {
				this.events.find( (el) => { 
					return el.id == data.id && el.start == data.start 
				}).className = data.className

				var calendarApi = this.$refs.fullCalendar.getApi()
				var eventSource = calendarApi.getEventSourceById(1)
				eventSource.refetch()
			},
			refreshDataCalendar(startDate, endDate) {
				if(this.data.from == 'user') {
					this.getUserResponsibilitiesData(startDate, endDate, true)
				} else {
					this.getRoleResponsibilitiesData(startDate, endDate, true)
				}
			}
		}
}
</script>